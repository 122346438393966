.features {
  padding: 100px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.features h2 {
  font-size: 36px;
  margin-bottom: 40px;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 40px;
}

.features li::before {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../assets/checkmark.png") no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
