.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: left;
}

.auth-form {
  border-radius: 0.5rem;
  max-width: 36rem;
  width: 100%;
  text-align: left;
  backdrop-filter: blur(10px);
}

@media (min-width: 768px) {
  .auth-form {
    /* padding: 5rem; Tailwind's p-20 */
  }
}

/* .auth-form h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #2e3133;
} */

.auth-form input {
  width: 100%;
  padding: 10px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-width: 1px;
  border-radius: 8px;
  border-color: #d0d5dd;
}

.auth-form input::placeholder {
  color: #7a7c7d;
}

.auth-form button {
  width: 100%;
  padding: 10px;
  background-color: #32a5ad;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-form button:hover {
  background-color: #2f848a;
}

.auth-form p a {
  color: #32a5ad;
  text-decoration: none;
}

.auth-form p a:hover {
  text-decoration: underline;
}
