body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.landing-page {
  text-align: center;
}

.header {
  background-color: #007bff;
  color: white;
  padding: 10px 0;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.header nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.hero {
  background: url("../assets/hero.jpg") no-repeat center center;
  background-size: cover;
  color: white;
  padding: 100px 20px;
}

.hero h2 {
  font-size: 48px;
  margin-bottom: 10px;
}

.hero p {
  font-size: 24px;
}

.demo,
.pricing,
.features,
.testimonials {
  padding: 50px 20px;
}

.demo img {
  max-width: 100%;
  height: auto;
}

.pricing .pricing-plans {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.pricing .plan {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  max-width: 200px;
  flex: 1;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  background: url("../assets/checkmark.png") no-repeat left center;
  padding-left: 40px;
  margin-bottom: 10px;
}

footer {
  background-color: #f8f9fa;
  padding: 20px 0;
}

footer p {
  margin: 0;
}
