.recording-area {
  text-align: center;
}

.mic {
  width: 100px;
  height: 100px;
  background: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  cursor: pointer;
  transition: background 0.3s ease;
}

.mic:hover {
  background: #0056b3;
}

.patient-name {
  margin: 20px 0;
}

.patient-name label {
  margin-right: 10px;
}

textarea {
  width: 80%;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: block;
  min-height: 30px;
}

button {
  background: #007bff;
  color: white;
  transition: background 0.3s ease;
  margin-top: 20px;
}

button:disabled {
  background: #6c757d;
}

button:hover:not(:disabled) {
  /* background: #0056b3; */
}
