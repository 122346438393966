.notes-list {
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  margin: 20px 0;
}

.note-item {
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: pre-wrap;
  /* Ensures that line breaks are preserved */
}

.note-item:hover {
  background-color: #f1f1f1;
}

.note-item:last-child {
  border-bottom: none;
}

.note-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: #007bff;
}

.note-content {
  font-size: 14px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  /* Ensures that line breaks are preserved */
}

.soap-note {
  font-size: 14px;
  color: #343a40;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

@media (min-width: 768px) {
  .notes-list {
    max-width: 300px;
  }
}
