.btn-primary,
.btn-secondary {
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.btn-secondary {
  background-color: #32a5ad;
  color: white;
}
